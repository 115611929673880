<script setup lang="ts">
import type { ILeaseInfo } from '@register'
import { leaseSectorObject } from '~/constants/LeaseSector'
import { leaseStatusObject } from '~/constants/LeaseStatus'

interface Props {
  leaseInfo: ILeaseInfo
}
const props = defineProps<Props>()

const leaseStatus = computed(() => props.leaseInfo.status ?? 'Unknown')
const status = computed(
  () => leaseStatusObject[leaseStatus.value as keyof typeof leaseStatusObject],
)

const leaseSector = computed(() => props.leaseInfo.sector ?? 'Unknown')
const sector = computed(
  () => leaseSectorObject[leaseSector.value as keyof typeof leaseSectorObject],
)
</script>

<template>
  <section class="w-full">
    <div class="flex justify-between">
      <div class="flex gap-2 self-center font-bold">
        <!-- Status -->
        <span class="font-normal">
          <span
            class="text-gray-550 rounded-full px-3 py-1 text-xs"
            :class="status.color"
          >
            {{ status.label }}
          </span>
        </span>

        <!-- Sector -->
        <span class="font-normal">
          <span
            class="bg-gray-550 border-gray-550 flex items-center gap-1 rounded-full border bg-opacity-20 px-3 py-1 text-xs text-white"
          >
            <Icon :name="sector.icon" />
            {{ sector.label }}
          </span>
        </span>
      </div>

      <div class="print:hidden">
        <slot name="actions"></slot>
      </div>
    </div>
  </section>
</template>
